import React from "react";
import SearchResultPage from "../../../templates/offplan-results-template";

const SearchResultResiLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            {/* <SearchResultPage 
                locationname="/off-plan-properties/for-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="for-rent" 
                pstatustype="to let"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Lettings"
            /> */}
        </React.Fragment>
    );
};

export default SearchResultResiLettings;
